import React, { useState } from "react"
import Modal, { ModalType } from "../components/Modal"
import LinkCustom from "../components/LinkCustom"
import SEO from "../components/SEO"
import styles from "../styles/modules/static-pages.module.scss"

const questions = [
  {
    question: <>How many cards can I order?</>,
    answer: <>As many as you'd like!</>,
  },
  {
    question: <>Can the cards be for various amounts?</>,
    answer: (
      <>
        Yes! Simply indicate amounts in the appropriate column in the order
        form. Note: amounts must be increments of $5.
      </>
    ),
  },
  {
    question: (
      <>
        Will I email the recipients their cards, or will NeighborShare email the
        cards?
      </>
    ),
    answer: (
      <>
        That's your choice!{" "}
        <a
          href="https://storage.googleapis.com/ns-assets-pd/reports/Screen%20Shot%202023-10-25%20at%2012.03.06%20PM.png"
          target="_blank"
        >
          Click here for a preview of the recipient email
        </a>
        . We can either send it automatically at a day and time of your choice
        or we can send them all to you for you to forward on at a time of your
        choosing or even print and wrap up!
      </>
    ),
  },
  {
    question: <>Do I have to provide the name of every recipient?</>,
    answer: (
      <>
        No, but it highly is recommended. If you'd simply like us to send you 20
        Giving Cards in the amount of $50, for example, we can do that. However,
        providing a name will help NeighborShare accelerate any potential
        customer service issues during redemption.
      </>
    ),
  },
]

const BulkOrder = () => {
  const [giftCardPurchaseOpen, setGiftCardPurchaseOpen] = useState(false)

  return (
    <>
      <Modal
        modalType={ModalType.BulkGift}
        modalOpen={giftCardPurchaseOpen}
        handleModalClose={() => {
          setGiftCardPurchaseOpen(false)
        }}
      />
      <SEO title="Bulk Order Giving Cards" />
      <div className="wrapper wrapper--left">
        <h2 className={`x-large-body-copy ${styles.title}`}>
          Bulk Order Giving Cards
        </h2>
        <p className={`body-copy`}>
          Thank you for your interest in ordering NeighborShare Giving Cards in
          bulk! This is a unique and meaningful option for employees, clients,
          and loved ones.
        </p>
        <br />
        <p className={`body-copy ${styles.paragraph}`}>
          If you're ready to place your order, here are the instructions:
        </p>

        {/* This should be where Rik will add the functionality to */}
        <ol>
          <li className={`body-copy ${styles.paragraph}`}>
            Download the{" "}
            <a
              href="https://storage.googleapis.com/ns-assets-pd/reports/NeighborShare%20Giving%20Card%20-%20Bulk%20Order%20Form.xlsx"
              target="_blank"
            >
              order form
            </a>
            .
          </li>
          <li className={`body-copy ${styles.paragraph}`}>
            Complete the order form and submit to{" "}
            <a href={"mailto:info@nbshare.org"}>info@nbshare.org</a>.
          </li>
          <li className={`body-copy ${styles.paragraph}`}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                setGiftCardPurchaseOpen(true)
              }}
            >
              Complete payment
            </a>
            .
          </li>
        </ol>
        <p className={`body-copy ${styles.paragraph}`}>
          If you have questions, please don't hesitate to reach out to{" "}
          <a href={"mailto:info@nbshare.org"}>info@nbshare.org</a>. Thank you so
          much for your support!
        </p>

        {questions.length && (
          <ul className="no-list">
            {questions.map((question, index) => (
              <li key={index.toString()}>
                <h3 className="header">{question.question}</h3>
                <p className={`body-copy ${styles.paragraph}`}>
                  {question.answer}
                  {!!question.link && (
                    <span className={styles.blockLink}>
                      <LinkCustom
                        scope={question.link.scope}
                        size="small"
                        url={question.link.url}
                      >
                        {question.link.label}
                      </LinkCustom>
                    </span>
                  )}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
      <p className={`body-copy ${styles.paragraph}`}>
        Additional questions? We'd love to hear from you! Send us a note at{" "}
        <a href={"mailto:info@nbshare.org"}>info@nbshare.org</a>
      </p>
    </>
  )
}

export default BulkOrder
